import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-8 text-justify">
      {/* Title */}
      <h1
        className="text-4xl text-center mb-6"
        style={{ fontFamily: "sansationbold" }}
      >
        Privacy Policy
      </h1>

      {/* Updated Date */}
      <p
        className="text-sm text-gray-500 mb-8 text-center"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        Updated: 24-11-2023
      </p>

      {/* Sections */}
      <div className="space-y-6">
        {/* Section 1: Data Controller */}
        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            I. Data Controller
          </h2>
          <p style={{ fontFamily: "Poppins" }}>
          1. The operator of the LAOS Foundation website available under https://laosnetwork.io (hereinafter referred to
as the &quot;Website&quot;) and offeror of blockchain-related services (such as currently e.g. the LAOS Network, the KLAOS Network  (hereinafter jointly and individually referred to as the &quot;Services&quot;) and, thus, the Data Controller
is the Laos Chain Foundation, a company registered in the commercial register of the Canton of Zug,
Switzerland (registration number CHE-192.209.904), with the registered address Vorderbergstrasse, 26, 6318
Walchwil(Zug), Switzerland (hereinafter referred to as &quot;Controller&quot;, &quot;we&quot;, &quot;us&quot;).</p>
          <p style={{ fontFamily: "Poppins" }}>2. We nurture and steward technologies and applications in the fields of decentralized web software protocols,
particularly those, which utilize modern cryptographic methods to safeguard decentralization. Data
protection is important to us and Controller adheres to the applicable data protection laws and regulations.
This includes both the Swiss Federal Act on Data Protection (&quot;FADP&quot;) and privacy requirements where
applicable to individuals in the European Union and the member states of EFTA under the General Data
Protection Regulation (hereinafter &quot;GDPR&quot;) and/or other applicable national laws.</p>
          <p style={{ fontFamily: "Poppins" }}>
            3.This Privacy Policy explains in particular how, for which purposes and to which extent your Personal Data is
collected and processed by us through the Website or any type of Service we provide to you (whenever we
are referring to you, hereinafter referred to as &quot;User&quot; or &quot;you&quot;). It also describes how your collected Personal
Data can be verified, corrected or deleted. Our Services enable the collection of your Personal Data necessary
for the establishment and maintenance of our blockchain-offerings.</p>
          <p style={{ fontFamily: "Poppins" }}>
            As outlined in Sections II and X.A below, if you engage with us and use any of our blockchain-offerings, we
may collect, by itself or through third parties, your data including, but not limited to: name, e-mail address,
usage data and any information captured automatically through cookies. Complete details on each type of
Personal Data collected are provided in the dedicated sections of this Privacy Policy or by specific
explanation displayed to you online prior to the data collection.</p>
          <p style={{ fontFamily: "Poppins" }}>This Website contains links to other third party websites. If you follow a link to any of those third party
websites, please note that they have their own privacy policies and that we do not accept any responsibility
or liability for their policies or their processing of your personal information.</p>
          <p style={{ fontFamily: "Poppins" }}>For questions or requests related to data processing by us (such as request for information, deletion,
revocation of consent, objection to data processing), you may revert by mail to the address above or write us
an e-mail at info@laosfoundation.io</p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            II. Types of Data collected
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. Controller respects the privacy of the User and will not collect and process any other data (such as name,
address, phone number, e-mail address, IP address, device type etc.) unless they are provided voluntarily by the User; gathered as a result of specific verifications performed by third parties included in Section X.C below
based on the Personal Data provided by the User; automatically collected by cookies or other tracking technologies. For further information with regard to
our use of cookies, please consult Section VII below.</p>
          <p style={{ fontFamily: "Poppins" }}>2. For further information on additional data collected through any of our blockchain-offerings, please consult
          Section XI below.</p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            III A. Mode of Processing: Use of Personal Data
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. Data transmitted by the User to Controller may be used as follows:
            <ul className="list-disc">
              <li>to create a user account;</li>
              <li>to respond to your inquiries and your correspondence;</li>
              <li>for marketing analysis purposes, in particular to better understand the needs of Users and improve the
Services of Controller, and to provide Users with relevant information relating to any of our networks
operated;</li>
              <li>to ensure our Website functions correctly, in particular to ensure that content from our Website is
              presented in the most effective manner for you and for your computer;</li>
              <li>to maintain or improve our services offered through the Website.</li>
            </ul>
          </p>
          <p style={{ fontFamily: "Poppins" }}>2. Please consult Section XI below to get further information on additional use of your data collected on any of
          our network offerings.</p>          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            III B. Mode of Processing: Legal basis of processing
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. The Controller may process Personal Data of Users if one of the following applies:
            <ul className="list-disc">
              <li>t Users have given their consent for one or more specific purposes. NoteE Under some legislations, the
Controller may be allowed to process Personal Data until the User objects to such processing (&quot;opt-out&quot;),
without having to rely on consent or any other of the following legal bases. This, however, does not apply,
whenever the processing of Personal Data is subject to European data protection law (GDPR);</li>
              <li>provision of Data is necessary for the performance of an agreement with the User and/or for any precontractual obligations thereof;</li>
              <li>processing is necessary for the establishment, exercise or defence of legal claims or proceedings;
operatedC</li>
              <li>processing is necessary for compliance with a legal and regulatory obligation to which the Controller is
              subject;</li>
              <li>processing is related to a task that is carried out in the public interest or in the exercise of official authority
              vested in the Controller;</li>
              <li>processing is necessary for the purposes of the legitimate interests pursued by the Controller or by a third
              party.</li>
            </ul>
          </p>
          <p style={{ fontFamily: "Poppins" }}>2. In any case, the Controller will gladly help to clarify the specific legal basis that applies to the processing, and
in particular whether the provision of Personal Data is a statutory or a contractual requirement, or a
requirement necessary to enter into a contract.</p>
          <p style={{ fontFamily: "Poppins" }}>3. Within and to the extent under the scope of application of the GDPR, the data processing described in this
clause III. is justified in order to provide our contractually agreed services to you pursuant to Art. 6 para. 1
sentence 1 letter b GDPR and to comply with legal obligations to which we are subject pursuant to Art. 6 para.
1 letter c GDPR.</p>          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            III C. Mode of Processing: Methods of processing, access to data and disclosure to third parties
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. The data processing is carried out using computers and/or IT enabled tools, following organizational
          procedures and modes strictly related to the purposes indicated.</p>
          <p style={{ fontFamily: "Poppins" }}>2. Access to Personal Data is limited to those employees and/or third parties assigned with processing tasks
who therefore need to know about this data. These employees and/or third parties are subject to
confidentiality undertakings and/or data processing agreements and must comply with applicable data
protection laws.</p>
          <p style={{ fontFamily: "Poppins" }}>3. Controller does not sell, transfer or market your Personal Data to third parties (who may use them for their
own purposes). However, we may disclose your Personal Data to trusted third parties and/or certain types of
persons in charge, involved with the operation of this Website (administration, sales, marketing, legal, system
administration) or external parties (such as third party technical service providers, mail carriers, hosting
providers, IT companies, communications agencies, our auditors, third parties involved in hosting or
organizing events or seminars) appointed, if necessary, as Data Processors by the Controller.</p>
          <p style={{ fontFamily: "Poppins" }}>4. Please consult Section XI below for lists of all third party processors currently assigned with processing
          activities on our behalf on any of our networks operated.</p>
          <p style={{ fontFamily: "Poppins" }}>5. Your Personal Data will not be disclosed to third parties for other purposes than the ones mentioned above or
          the following additional reasons:
          <ul className="list-disc">
            <li>you have given your express consent to it;</li>
            <li>the disclosure is necessary to assert, exercise or defend legal claims and there is no reason to assume
              that you have an overriding interest worthy of protection in the non-disclosure of your data;</li>
            <li>in the event that a legal obligation exists for the disclosure; or</li>
            <li>this is legally permissible and necessary for the execution of our contractual relationship with you.</li>
          </ul>
          </p>          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            III D. Mode of Processing: Place of processing and export of data
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. The data is processed at the Controller&apos;s operating offices and in any other places where the parties involved
in the processing are located. Depending on the User&apos;s location: data transfers may involve transferring the
User&apos;s data to a country other than their own.</p>
          <p style={{ fontFamily: "Poppins" }}>2. Therefore: we reserve the right to transfer: store: use and process your data: including any personal
information: to/by recipients in countries outside of the European Economic Area (&quot;EEA&quot;) including the United
States and possibly other countries. You should note that laws vary from jurisdiction to jurisdiction: and so
laws and regulations relating to privacy and data disclosure: applicable to the places where your information
is transferred to or stored: used or processed in: may not provide the same level of protection as in your place
of residency. We take the legally required safeguards and contractual measures to ensure that any
recipients of your Personal Data abroad undertake to comply with the level of data protection and security
prescribed by your applicable local data protection legislation.</p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            IV.  Retention of data
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. The Controller will retain Personal Data for as long as it is required to deliver the Services described in
Sections III.A above and X.+ below: and/or: upon termination: as long as required by law or regulations (e.g.
mandatory retention periods): whichever is longer. Therefore,
          <ul className="list-disc">
            <li>Personal Data collected for purposes related to the performance of a contract between the Controller and
            the User shall be retained until such contract has been fully performed;</li>
            <li> Personal Data collected for the purposes of the Controller&apos;s legitimate interests shall be retained as long
            as needed to fulfil such purposes.</li>
          </ul>
          </p>
          <p style={{ fontFamily: "Poppins" }}>2. The Controller may be allowed to retain Personal Data for a longer period whenever the User has given
consent to such processing: as long as such consent is not withdrawn.

</p>
          <p style={{ fontFamily: "Poppins" }}>3. Once the retention period expires: Personal Data shall be deleted. Therefore: the right to access: the right to
erasure: the right to rectification and the right to data portability cannot be enforced after expiration of the
retention period.</p>       
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            V. Security measures
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. We take adequate technical and organizational precautions and security measures to prevent accidental or
intentional manipulation: unauthorized access: disclosure: unauthorized destruction: partial or complete loss:
misuse or alteration of your Personal Data. Accordingly: we store all the personal information you provide on
secure (password- and firewall-protected) servers. Our security measures are continuously improved in line
with technical developments. You are responsible for keeping the account information for accessing any of
our networks operated confidential.</p>
          <p style={{ fontFamily: "Poppins" }}>2. The User is aware and acknowledges that no technical and organizational measures can fully eliminate
security risks connected with transmission of information over the Internet. Once Controller has received the
transmitted information: it shall adequately secure it in its systems.</p>
         
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            VI. The Rights of Users
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. Users may exercise certain rights regarding their Personal Data processed by the Controller.
In particular, Users have the right to do the following:
        <ul className="list-disc">
          <li>Withdraw their consent at any time. Users have the right to withdraw consent where they have previously
given their consent to the processing of their Personal Data. Please note that even after you have chosen
to withdraw your consent, we may be able to continue to process your Personal Data to the extent
required or permitted by law.</li>
          <li>Object to processing of their data, Users have the right to object to the processing of their data if the
processing is carried out on a legal basis other than consent (e.g. for a public interest, in the exercise of an
official authority vested in the Controller or for the purpose of legitimate interests pursued by the
Controller). Users may object to such processing by providing a ground related to their particular situation
to justify the objection. In particular, under and to the extent of a scope of application of the GDPR, in those
cases where we base processing on our legitimate interests, you have the right to object to the
processingA Users must know that, however, should their Personal Data be processed for direct marketing
purposes, they can object to that processing at any time without providing any justification.</li>
          <li>Access their data. Users have the right to learn if the Controller is processing data, obtain disclosure
          regrading certain aspects of the processing and obtain a copy of the data undergoing processing@</li>
          <li>Verify and seek rectification. Users have the right to verify the accuracy of their data and ask for it to be
updated or correctedA Please note that you must advise us of any changes to your personal information
so that we can ensure that your personal information is accurate and up to date.</li>
          <li>Restrict the processing of their data. Users have the right, under certain circumstances, to restrict the
processing of their data if the accuracy of the data is disputed. In this case, the Controller will not process
their data for any purpose other than storing it.</li>
          <li>Restrict the use of Personal Data whilst complaints are resolved.</li>
          <li>Have their Personal Data deleted or otherwise removed.</li>
          <li>Users have the right, under certain circumstances, to obtain the erasure of their data from the Controller,
unless the processing is justified by our legitimate interests, necessary to fulfil a legal obligation, for
reasons of public interest or to assert, exercise or defend legal claims. We will take reasonable steps to
inform other controllers that are processing the data that you have requested the erasure of any links to,
copies or replication of it.</li>
          <li>Receive their data and have it transferred to another controller. Users have the right to receive their data
in a structured, commonly used and machine readable format and, if technically feasible, to have it
transmitted to another controller without any hindrance. This provision is applicable provided that the
data is processed by automated means and that the processing is based on the User&apos;s consent, on a
contract which the User is part of or on pre-contractual obligations thereof.</li>
          <li>Lodge a complaintA Users have the right to bring a claim before their competent data protection authority
(depending on your country of residence and the applicable data protection laws – note that in certain
countries you may only notify a data protection authority which may then decide to initiate legal steps
based on its own discretion).</li>
        </ul>

</p>
          <p style={{ fontFamily: "Poppins" }}>2. Any requests to exercise User rights can be directed to the Controller through the contact details provided in
          this document.</p>
          <p style={{ fontFamily: "Poppins" }}>3. Where possible, Controller will fulfil such a request of the User within the statutory applicable timeframe,
unless a delay or a retention of the relevant data is permitted by law (e.g. a lack of convincing identity proof
by an information requestor), is required for another valid purpose, for example to enable the fulfilment of
contractual obligations, or is covered by a valid limitation or exemption under relevant privacy or data
protection regulations.</p>
          <p style={{ fontFamily: "Poppins" }}>4. Any requests will be free of charge, provided we do not incur unexpected and inadequate costs for providing
          you with details of your Personal Data.</p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            VII. Cookies
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. When the User visits the Website, information can be automatically stored on his or her computer. This is
done in the form of so-called &quot;cookies&quot; or a similar file, which help Controller in various ways, for example, to
get to know the preferences of visitors and Users of the Website and to improve the Website. Both permanent
cookies and functional, temporary session cookies may be used: permanent cookies remain on your
computer after you close your session and until you delete them, while session cookies expire when you close
your browser.</p>
          <p style={{ fontFamily: "Poppins" }}>2. Any use of Cookies – or of other tracking tools – by this Website or by the Controller of third-party services
used by this Website serves the purpose of providing the Service required by the User, in addition to any other
purposes described in the present document and in the Cookie Notice, if available. Please consult Section XI
below to get information on any Cookie Notices available on any of our networks operated.</p>
          <p style={{ fontFamily: "Poppins" }}>3. You may refuse the use of any cookies by selecting the appropriate settings on your browser. Most browsers
allow you to delete cookies, prevent their installation or generate a warning before a cookie is installed. The
User can obtain further information on this subject from the relevant browser instructions. Note, however, that
this may affect your experience of our Website. To find out more about cookies, including how to manage,
reject and delete cookies, visit www.allaboutcookies.org.</p>
          <p style={{ fontFamily: "Poppins" }}>4. Controller will use automatically stored information exclusively for statistical analysis and in particular will not
associate any Personal Data with the User unless necessary. This Notice does not limit our use or disclosure to
third parties of Non-Personal Information, and we reserve the right to use and disclose such Non-Personal
Information to our partners, advertisers, and other third parties at our discretion.</p>
          <p style={{ fontFamily: "Poppins" }}>5. Within and to the extent under the scope of application of the GDPR, the data processed by cookies for the
aforementioned purposes is justified in order to protect our legitimate interest and those of third parties
pursuant to Art. 6 para. 1 sentence 1 letter f GDPR.</p>
          <p style={{ fontFamily: "Poppins" }}>6. Google Analytics. We inform you that to get information about the behaviour of our user, we use Google
Analytics. This analytics software gives us insight about our user only in general, but not about individuals, as
it does not track visitors and does not store any personal identifiable information. If you would like to, please
go to their website to find out what Google Analytics collects (and most importantly what they don’t). Further
information on Google Analytics can be found in the terms of use of Google Analytics, the data protection
guidelines of Google Analytics and the data protection regulations of Google: https://policies.google.com/
privacy</p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            VIII. Changes to This Privacy Policy
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. We reserve the right to make changes to this Privacy Policy at any time by giving notice to Users on this page
and possibly within the Website and/or – as far as technically and legally feasible – sending a notice to Users
via any contact information available to the Controller. Significant changes will go into effect 30 days
following such notification. Non-material changes or clarifications will take effect immediately. It is strongly
recommended to review the Website and the Privacy Notice periodically for updates.</p>
          <p style={{ fontFamily: "Poppins" }}>2. Should the changes affect processing activities performed on the basis of the User&apos;s consent, Controller shall
          collect consent from the User, where required.</p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            IX. Access to the Privacy Policy
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. The User can access, download, save or print this Privacy Policy in its current/updated version at any time.</p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            X.  Definitions and legal references
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. Personal Data. Any information that directly, indirectly, or in connection with other information – including a personal
          identification number – allows for the identification or identifiability of a natural person.</p>
          <p style={{ fontFamily: "Poppins" }}>2. Usage Data. Information collected automatically through this Website (or third-party services employed in this Website),
which can include: the IP addresses or domain names of the computers utilized by the Users who use this
Website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to
submit the request to the server, the size of the file received in response, the numerical code indicating the
status of the server&apos;s answer (successful outcome, error, etc.), the country of origin, the features of the
browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent
on each page within the Website) and the details about the path followed within the Website with special
reference to the sequence of pages visited, and other parameters about the device operating system and/or
the User&apos;s IT environment.</p>
          <p style={{ fontFamily: "Poppins" }}>3. User. The individual using this Website who, unless otherwise specified, coincides with the Data Subject.</p>
          <p style={{ fontFamily: "Poppins" }}>4. Data Subject. The natural person to whom the Personal Data refers.</p>
          <p style={{ fontFamily: "Poppins" }}>5. The natural or legal person, public authority, agency or other body which processes Personal Data on behalf
          of the Controller, as described in this Privacy Policy.</p>
          <p style={{ fontFamily: "Poppins" }}>6. Data Controller. The natural or legal person, public authority, agency or other body which, alone or jointly with others,
determines the purposes and means of the processing of Personal Data, including the security measures
concerning the operation and use of this Website. The Data Controller, unless otherwise specified, is the
owner of this Website.</p>
          <p style={{ fontFamily: "Poppins" }}>7. Website. The Website of Controller available under https://laosfoundation.io.

</p>
          <p style={{ fontFamily: "Poppins" }}>8. Service. The Services (and blockchain offerings) provided by Controller.</p>
          <p style={{ fontFamily: "Poppins" }}>9. European Union (or EU). Unless otherwise specified, all references made within this document to the European Union include all
          current member states to the European Union and the European Economic Area.</p>
          <p style={{ fontFamily: "Poppins" }}>10. Cookies. Small sets of data stored in the User&apos;s device.</p>
          <p style={{ fontFamily: "Poppins" }}>11. Legal Information. This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of
Regulation (EU) 2016/679 (General Data Protection Regulation).
<p style={{ fontFamily: "Poppins" }}>12. Games and Applications. Any game or application, made available by the LAOS Foundation, and installable on either mobile or desktop devices, either via direct download from the website, or from common third-party platforms such as the Apple App Store, Google Play Store, Epic Games Store, or Steam.</p>
This Privacy Policy relates solely to this Website and Services, if not stated otherwise within this document.</p>
          <p style={{ fontFamily: "Poppins" }}>Effective Date. This Privacy Notice was created on and has been in effect since the 24.November 2023. We reserve the right,
at our complete discretion, to change, modify, add, or remove portions of this Privacy Policy at any time. You
should check back periodically at this page to read the most recent version of this Privacy Policy as your
continued use of this Website following the posting of changes to these terms will mean you acknowledge
these changes.</p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            XI. Special provisions: LAOS Foundation Website
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. The following third party processors are currently assigned with processing activities on our behalf - Freeverse S.L. -	Website: httpsI//www.freeverse.io</p>
          <p style={{ fontFamily: "Poppins" }}>2. The updated list of these parties may be requested from the Controller at any time.</p>
          <p style={{ fontFamily: "Poppins" }}>3. The Laos Foundation Website uses cookies to help us provide you
          with a good experience when you browse this Website and allow us to improve our Website. </p>          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            XI. Special provisions: LAOS Network
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. The following third party processors are currently assigned with processing activities on our behalf - Freeverse S.L. -	Website: https://www.freeverse.io. Information is disclosed to Freeverse S.L. for the purposes of assisting and/or following up with you, and
coordinating that assistance/follow-up where you have shown interest in the LAOS Network. In particular,
Freeverse is assigned with forwarding technical updates to the LAOS Network to users.</p>
          <p style={{ fontFamily: "Poppins" }}>2. Cookies on the on the (Sub-)Website www.laosnetwork.io: The LAOS Network Website uses cookies to help us
          provide you with a good experience when you browse this Website and allow us to improve our Website. </p>
          
        </section>

        <section>
          <h2
            className="text-2xl mb-2"
            style={{ fontFamily: "sansationbold" }}
          >
            XI. Special provisions: Games and Applications
          </h2>
          <p style={{ fontFamily: "Poppins" }}>1. The following third party processors are currently assigned with processing activities on our behalf - Freeverse S.L. -	Website: https://www.freeverse.io. Information is disclosed to Freeverse S.L. for the purposes of assisting and/or following up with you, and
coordinating that assistance/follow-up where you have shown interest in LAOS Foundation games and Applications. In particular,
Freeverse is assigned with forwarding technical updates to the LAOS Foundation games and applications to users.</p>
          <p style={{ fontFamily: "Poppins" }}>2. By downloading, installing, or using the Game or Application, you agree to the terms outlined in this Privacy Policy. If you do not agree, please refrain from using the Game or Application.</p>
          <p style={{ fontFamily: "Poppins" }}>3. The Game or Application may include advertisements provided by third-party ad networks. These services may use Cookies or similar technologies to serve personalized ads. You can opt-out of personalized ads via your device settings.</p>
          <p style={{ fontFamily: "Poppins" }}>4. Additional Data may be collected in the form of:
            <ul className="list-disc">
              <li>Device Information: Such as your device model, operating system version, and unique device identifiers.</li>
              <li>Usage Data: Information on how you interact with the game, including gameplay progress, scores, preferences, and session length.</li>
              <li>Analytics Data: Data collected through third-party analytics tools to improve gameplay and performance.</li> 
              
            </ul>
          </p>
          
        </section>

    

      </div>
    </div>
  );
};

export default PrivacyPolicy;
