import i_slides_image1 from "./img/1.jpg"
import i_slides_image2 from "./img/2.jpg"
import i_slides_image3 from "./img/3.jpg"

var CaseStudyData = {
  release: "Coming in 2025",
  title: "Life's Meta",
  text: `Life's Meta is an AI-powered social simulation platform that merges Web3 
  technology with immersive, AI-driven experiences. By integrating LAOS Network's 
  cutting-edge blockchain infrastructure, Life's Meta is set to redefine virtual 
  social interactions with a robust ecosystem that offers players true ownership, 
  customization, and in-game progression.`,
  main_image: i_slides_image1,
  button_text: "More info",
  button_link: "https://www.lifesmeta.io/",
  developer: "Widow Games",
  genre: "Simulation",
  OS: "Mobile",
  slider_images: [
    i_slides_image1,
    i_slides_image2,
    i_slides_image3,
  ],
  body_title: "Redefining Virtual Social Interactions",
  body_text_paras: [
    `Life's Meta, a pioneering social simulator, enables users to engage with AI 
    characters and shape their personal narratives in a dynamic world. Combining 
    the best elements of social gaming with the innovations of Web3, Life's Meta 
    allows players to explore an evolving virtual environment, establish meaningful 
    connections, and immerse themselves in interactive storylines that respond to 
    their actions and choices. Life's Meta will leverage LAOS Network's advanced 
    NFT functionalities to introduce a new era of digital ownership and player engagement.`,
    `Through LAOS Network, Life's Meta will integrate tokenized assets that enhance 
    the social simulator's dynamic economy, enabling players to own and trade a
     variety of digital assets. Key categories include Avatar Fashion (players can 
     customize their avatars with clothing, accessories, and hairstyles, offering a 
     unique, personalized look); Home Decor (items like furniture and artwork allow 
     players to personalize and decorate their virtual homes; Exclusive Access Passes 
     (special assets grant access to unique locations, events, and features, creating 
     an added layer of exclusivity and immersion; and Thematic Expansions (assets 
     that unlock new areas and storyline elements, providing fresh content for players 
     as they progress.`,
    `Life's Meta's marketplace, powered by LAOS Network, will enable players to securely 
    trade assets and access exclusive in-game content through limited-edition drops. 
    Prior to the platform's full launch, a series of presale drops will introduce 
    foundational assets that can evolve into more complex and valuable items over time. 
    Each asset, whether unlocked through achievements or traded within the player community,
    contributes to Life's Meta's vision of an interconnected and community-driven virtual 
    economy.`,
    `Through this innovative approach, LAOS Network and Life’s Meta are pushing the boundaries 
    of digital ownership and AI-driven storytelling. By creating a seamless user experience,
     integrating blockchain with dynamic social simulation, and offering a vast array of 
     collectible assets, the partnership aims to shape the future of interactive Web3 
     experiences.`,

  ],
}
export default CaseStudyData;