import React from "react";
import css from "./HomeBanner.module.css";
import lightning from "../img/lightning-charge.svg";

const HomeBanner = () => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <div
      className={css.banner} 
    >
      <img
        src={lightning}
        width="16"
        height="16"
        alt="LAOS Token
        "
      />{" "}
      <a data-testid="banner-text"
        href="/laos-token"
        target="_self">
        The $LAOS token is live on MEXC and Bit2Me!
      </a>
    </div>
  );
};

export default HomeBanner;
