import i_slides_image1 from "./img/1.jpg"
import i_slides_image2 from "./img/2.jpg"
import i_slides_image3 from "./img/3.jpg"
import i_slides_image4 from "./img/4.jpg"
import i_slides_image5 from "./img/5.jpg"
import i_slides_image6 from "./img/6.jpg"
import i_slides_image7 from "./img/7.jpg"
import i_slides_image8 from "./img/8.jpg"


var CaseStudyData = {
  release: "Early Access",
  title: "Battle Of Chains",
  text: `Join the battle of blockchains in a completely new kind of game, made
  possible only due to LAOS Networks Bridgeless Minting across multiple chains.`,
  main_image: i_slides_image1,
  button_text: "More info",
  button_link: "https://www.laosnetwork.io/",
  developer: "LAOS Network",
  genre: "Strategy",
  OS: "Web",
  slider_images: [
    i_slides_image1,
    i_slides_image2,
    i_slides_image3,
    i_slides_image4,
    i_slides_image5,
    i_slides_image6,
    i_slides_image7,
    i_slides_image8,
  ],
  body_title: "Join the Battle of Chains!",
  body_text_paras: [
    `Join the battle of blockchains in a completely new kind of game, made
  possible only due to LAOS Networks Bridgeless Minting across multiple chains.`,
  ],
}
export default CaseStudyData;