/* eslint-disable react/prop-types */ 
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import {useLocation} from "react-router-dom";

const Layout = () => {

  let location = useLocation();
  // console.log(location.pathname);

  let bg_css = "p-0 m-0";
  if (location.pathname === "/" || location.pathname.includes("/case-studies") || location.pathname.includes("/grant-program"))
    bg_css = "background-sun p-0 m-0";
  if (location.pathname.includes("/laos-token"))
    bg_css = "background-sun-center p-0 m-0";
  
  return (
    <>
    {/* <div className="bg-fvdarkpurple text-white background-image"> */}
    <div className={location.pathname === "/" ? "background-image text-white" : "bg-[#211434] text-white"}>
      <div className={bg_css}>
        <Header />
        <Outlet />
      </div>
      <Footer />
    </div>
    </>
  )
};

export default Layout;