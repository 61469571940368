import React from "react";
import laos_logo from "../img/LAOS_logo.png";
import freeverse_logo from "../img/logo_freeverse.png";
import icon_discord from "../img/discord.svg";
import icon_x from "../img/x_icon.svg";
import icon_telegram from "../img/telegram.png";
import icon_linkedin from "../img/Linkedin_Icon.svg";
import icon_github from "../img/github_logo.svg";
import icon_youtube from "../img/Youtube_Icon.svg";

const Footer = () => {
  return (
    <footer className="footer pb-2">
      <hr className="w-[100%] h-[2px] rounded border-0 hr-colored " />
      <div className="container mx-auto px-20 py-10">
        <div className="pt-2 flex items-center space-x-4 mb-10">
          <img src={laos_logo} alt="LAOS Logo" className="h-20" />
          <div>
            <div className="header-title tracking-widest text-fvyellow text-5xl">
              LAOS
            </div>
            <div className="header-title tracking-widest text-white text-xs">
              The home of digital assets
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-4 place-items-start mr-20">
            <a href="/#section-usecases">Use Cases</a>
            <a href="/#section-benefits">Benefits</a>
            <a href="https://medium.com/laosnetwork">Blog</a>
            <a href="/about/">About Us</a>
            <a href="/#community">Community</a>
            <a href={process.env.PUBLIC_URL + "/downloads/Disclaimer.pdf"}>
              Disclaimer
            </a>
            <a
              href={process.env.PUBLIC_URL + "/downloads/Legal_Disclosures.pdf"}
            >
              Legal Disclosure
            </a>
            <a href={process.env.PUBLIC_URL + "/privacy"}>
              Privacy
            </a>
          </div>
          <hr className="block md:hidden w-[100%] h-[2px] my-4 rounded border-0 hr-colored" />
          <div className="hidden md:block vert-color-border"></div>
          <div className="md:ml-20">
            <h6 className="mb-5">Connect with us</h6>
            <div className="flex gap-5">
              <a href="https://discord.gg/gZcxsJcdPy">
                <img className="h-[20px]" src={icon_discord} alt="Discord" />
              </a>
              <a href="https://twitter.com/laosnetwork">
                <img className="h-[20px]" src={icon_x} alt="X" />
              </a>
              <a href="https://t.me/+qI2I19EI-VAzNGVk">
                <img className="h-[20px]" src={icon_telegram} alt="Telegram" />
              </a>
              <a href="https://www.linkedin.com/company/laosnetwork/">
                <img className="h-[20px]" src={icon_linkedin} alt="LinkedIn" />
              </a>
              <a href="https://github.com/freeverseio/laos">
                <img className="h-[20px]" src={icon_github} alt="Github" />
              </a>
              <a href="https://www.youtube.com/@laosnetwork">
                <img className="h-[20px]" src={icon_youtube} alt="Youtube" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <p className="mb-3">Built by</p>
          <img src={freeverse_logo} alt="Freeverse" />
          <p className="text-xs mt-1">
            &copy; 2023 LAOS Foundation. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
