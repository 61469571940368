import i_slides_image1 from "./img/1.jpg"

var CaseStudyData = {
  release: "Coming in 2025",
  title: "The Mines Run",
  text: `Launching on Ethereum, and set in a dystopian Metaverse, The Mines Run challenges players to 
  navigate perilous underground mines, avoid obstacles, collect valuable rewards, 
  and face blockchain-themed dangers before their MetaOxygen runs out.`,
  main_image: i_slides_image1,
  button_text: "More info",
  button_link: "https://themines.io/",
  developer: "Picksels",
  genre: "Adventure",
  OS: "Desktop, Web",
  slider_images: [

  ],
  body_title: "Embark on a thrilling blockchain-powered cyberpunk adventure!",
  body_text_paras: [
    `Set in a dystopian Metaverse, The Mines Run challenges players to 
  navigate perilous underground mines, avoid obstacles, collect valuable rewards, 
  and face blockchain-themed dangers before their MetaOxygen runs out. The game 
  introduces blockchain mechanics that allow players to mint their characters into NFTs, 
  opening the door to customizations, progression, and ownership of unique in-game assets. 
  Initially launching on iOS and Android, The Mines Run offers an immersive experience 
  that bridges traditional mobile gaming and Web3 technology.`,
  `Through the LAOS Network's blockchain infrastructure, The Mines Run will offer NFT 
  minting, character evolution, and permanent storage of in-game achievements. Players 
  will be able to upgrade their characters by gathering in-game resources, then minting 
  these NFTs to unlock special abilities and customizations that are stored securely 
  on the blockchain. This integration creates a new level of ownership and permanence 
  in mobile gaming.`,
  `Alongside regular gameplay, The Mines Run will introduce exclusive Genesis NFTs 
  that grant players unique advantages, such as early access to future Picksels games, 
  exclusive skins, and participation in a secret project. These NFTs will be minted and 
  validated on the LAOS blockchain, ensuring their uniqueness and long-term value.`,

  ],
}
export default CaseStudyData;