import i_slides_main from "./img/grmain.jpg"
import i_slides_image1 from "./img/1.jpg"
import i_slides_image2 from "./img/2.jpg"
import i_slides_image3 from "./img/3.jpg"
import i_slides_image4 from "./img/4.jpg"
import i_slides_image5 from "./img/5.jpg"


var CaseStudyData = {
  release: "Early Access",
  title: "Goal Revolution",
  text: `Manage your football team, train your player, and trade with others. 
  Goal Revolution is a mobile multiplayer online football manager and trading game, 
  launching on mobile and desktop. Set your team and tactics, play against other users, 
  and rise up the leagues.`,
  main_image: i_slides_main,
  button_text: "More info",
  button_link: "https://www.laosnetwork.io/",
  developer: "LAOS Network",
  genre: "Sports Strategy",
  OS: "Web",
  slider_images: [
    i_slides_image1,
    i_slides_image2,
    i_slides_image3,
    i_slides_image4,
    i_slides_image5,
  ],
  body_title: `A revolution is coming`,
  body_text_paras: [
    `Goal Revolution is a mobile multiplayer online football manager and trading game, 
    launching on mobile and desktop. Set your team and tactics, play against other users, 
    and rise up the leagues.`,
    `Your players are key to your success. Set your formation with your best players in 
    key positions. Rotate your squad to keep them from being too tired, and use Training 
    Points to improve their core skills: Shoot, Speed, Pass, Defence, and Stamina.`,
    `As your player's skills improve, so does their value. With a web3 element powered by 
    laosnetwork.io, you can trade your players with other managers open the open market!`,

  ],
}
export default CaseStudyData;