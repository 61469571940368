import image_networkmenu from './menuimgs/icon_1.png'
import image_buildmenu from './menuimgs/icon_2.png'
import image_communitymenu from './menuimgs/icon_3.png'
import image_aboutmenu from './menuimgs/icon_4.png'
import icon_menu_documentation from './menuimgs/icon_menu_documentation.svg'
import icon_menu_block_explorer from './menuimgs/icon_menu_block_explorer.svg'
import icon_menu_blog from './menuimgs/icon_menu_blog.svg'
import icon_menu_governance from './menuimgs/icon_menu_governance.svg'
import icon_menu_grants from './menuimgs/icon_menu_grants.svg'
import icon_menu_join from './menuimgs/icon_menu_join.svg'
import icon_menu_laos_token from './menuimgs/icon_menu_laos_token.svg'
// import icon_menu_presskit from './menuimgs/icon_menu_presskit.svg'
import icon_menu_roadmap from './menuimgs/icon_menu_roadmap.svg'
import icon_menu_staking from './menuimgs/icon_menu_staking.svg'
import icon_menu_use_cases from './menuimgs/icon_menu_use_cases.svg'
import icon_menu_vesting from './menuimgs/icon_menu_vesting.svg'
import icon_menu_wallet from './menuimgs/icon_menu_wallet.svg'
import icon_menu_whitepaper from './menuimgs/icon_menu_whitepaper.svg'

export const NetworkMenu = {
    title: "Network",
    text: "Information regarding the LAOS Network and token",
    image: image_networkmenu,
    link: "/laos-token",
    menu_items: [
        {
            title: "Token",
            desc: "Learn about $LAOS token and where to exchange it",
            icon: icon_menu_laos_token,
            link: "/laos-token",
            target: "_blank"
            
        },
        {
            title: "Block Explorer",
            desc: "Live Transactions on the LAOS Network",
            icon: icon_menu_block_explorer,
            link: "https://blockscout.laos.laosfoundation.io/",
            target: "_blank"
        },
        {
            title: "Staking",
            desc: "Stake your LAOS tokens",
            icon: icon_menu_staking,
            link: "https://staking.laosnetwork.io/",
            target: "_blank"
        },
        {
            title: "Vesting",
            desc: "View the schedule for vested LAOS tokens",
            icon: icon_menu_vesting,
            link: "https://vesting.laosnetwork.io/",
            target: "_blank"
        },
        {
            title: "Wallet Compatibility",
            desc: "Find our which wallets are compatible with the LAOS token",
            icon: icon_menu_wallet,
            link: "https://laosnetwork.io/wallet-compatibility-roadmap",
            target: "_blank"
        },
        {
            title: "Technical Whitepaper",
            desc: "Read the original whitepaper behind LAOS' technology",
            icon: icon_menu_whitepaper,
            link: "https://github.com/freeverseio/laos-whitepaper/blob/main/laos.pdf",
            target: "_blank"
        },
        {
            title: "Roadmap",
            desc: "Discover what's coming next for LAOS",
            icon: icon_menu_roadmap,
            link: "https://laosnetwork.io/roadmap-after-tge",
            target: "_self"
        },

    ]
}

export const BuildMenu = {
    title: "Build",
    text: "Learn how to build your app or game on LAOS, save on minting costs, and eliminate congestion.",
    image: image_buildmenu,
    link: "/build",
    menu_items: [
        {
            title: "Build on LAOS",
            desc: "Information hub for builders",
            icon: icon_menu_use_cases,
            link: "/build",
            target: "_self"
        },
        {
            title: "Documentation",
            desc: "Technical documentation for building on LAOS",
            icon: icon_menu_documentation,
            link: "https://docs.laosnetwork.io/",
            target: "_blank"
        },
        // {
        //     title: "Indexer",
        //     desc: "The LAOS open-source Indexer",
        //     icon: icon_menu_block_explorer,
        //     link: "#"
        // },
        // {
        //     title: "API",
        //     desc: "GraphQL API for building on LAOS",
        //     icon: icon_menu_staking,
        //     link: "#"
        // },
        {
            title: "Use Cases",
            desc: "Discover how LAOS can help you",
            icon: icon_menu_use_cases,
            link: "/#section-usecases",
            target: "_self"
        },
        {
            title: "Grants",
            desc: "Apply to the LAOS Builder's Program",
            icon: icon_menu_grants,
            link: "/grant-program",
            target: "_self"
        }
    ]
}

export const CommunityMenu = {
    title: "Community",
    text: "Join our growing community and help decide LAOS' future.",
    image: image_communitymenu,
    link: "/community",
    menu_items: [
        {
            title: "Join",
            desc: "Take part across various channels",
            icon: icon_menu_join,
            link: "/community",
            target: "_self"
        },
        {
            title: "Blog",
            desc: "Read the latest news about LAOS",
            icon: icon_menu_blog,
            link: "https://medium.com/laosnetwork",
            target: "_blank"
        },
        // {
        //     title: "Governance",
        //     desc: "Decide the future of LAOS",
        //     icon: icon_menu_governance,
        //     link: "/"
        // },
        {
            title: "Grants",
            desc: "Help us promote LAOS",
            icon: icon_menu_grants,
            link: "/grant-program",
            target: "_self"
        }
    ]
}

export const AboutMenu = {
    title: "About LAOS",
    text: "Learn more about LAOS and team behind it",
    image: image_aboutmenu,
    link: "/about",
    menu_items: [
        {
            title: "About LAOS",
            desc: "Find out more about LAOS' founders and backers",
            icon: icon_menu_governance,
            link: "/about",
            target: "_self"
        },
        // {
        //     title: "Contact",
        //     desc: "Get in touch",
        //     icon: icon_menu_join,
        //     link: "/"
        // },
        // {
        //     title: "Press Kit",
        //     desc: "Download our press kit here",
        //     icon: icon_menu_presskit,
        //     link: "/"
        // }
    ]
}