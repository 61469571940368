/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import UseCases from "./components/UseCases"
// import AssetsMinted from "./components/AssetsMinted";
// import GasCalculator from "./components/GasCalculator";
import EmbedVideo from "./components/EmbedVideo";
import Newsletter from "./components/Newsletter";
import CommunityPanels from "./components/CommunityPanels";
import PoweredByLAOS from "./components/PoweredByLAOS";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import logo_eth from "../img/logo_eth.png";
import logo_pol from "../img/logo_polygon.png";
import logo_bin from "../img/logo_binance.png";
import logo_cron from "../img/logo_cronos.png";
import logo_op from "../img/logo_op.png";
import logo_arb from "../img/logo_arbitrum.png";
import logo_early from "../img/earlybird_logo_1.png";
import logo_target from "../img/target_global_logo_1.png";
import logo_adara from "../img/Adara_logo_1.png";
import logo_4F from "../img/4founders_logo_1.png";
import logo_polka from "../img/polkadot_parachain_logo.svg";
import benefit_icon_bridgeless from "../img/icon_bridgeless.png";
import benefit_icon_interop from "../img/icon_interop.png";
import benefit_icon_scale from "../img/icon_scalability.png";


// import roadmap from "../img/LAOS_ROADMAP.png";


const Home = () => {
  useEffect(() => {
    if (window.location.hash === "#community") {
      const element = document.getElementById("community");
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  const [settings] = useState({
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
  });
  const logos = [logo_eth, logo_pol, logo_bin, logo_cron, logo_op, logo_arb];

  
  return (
    <>
      <div className="container mx-auto px-10 pb-0">
        {/* <!-- Hero --> */}
        <section>
          <div className="grid grid-cols-1 sm:grid-cols-3 place-items-center">
            <div className="col-span-2 pt-10 text-center sm:text-left">
              <h1 className="sm:w-3/4 pb-4 header-title text-3xl md:text-5xl leading-none lg:leading-tight">
              The Layer-1 to create millions of assets on any EVM chain</h1>
              <h2 className="sm:w-3/4 pb-10 header-title text-xl md:text-3xl leading-none lg:leading-tight">
              Mint NFTs <span className="text-fvyellow">100x cheaper</span> than any other blockchain</h2>
              <div className="grid grid-cols-1 md:w-100 md:flex md:space-x-4 space-y-4 md:space-y-0 md:justify-start">
                <a
                  href="https://docs.laosnetwork.io/"
                  className="border bg-fvyellow border-fvyellow text-fvpurple px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
                >
                  Start Building
                </a>
                <a
                  href="https://testnet.apps.laosnetwork.io/"
                  className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                >
                  Try Bridgeless Minting Now
                </a>
              </div>
            </div>
            <div className="">
              {/* <div id="3d-ball" className=""></div> */}
              <iframe
                title="3d-ball"
                height="300"
                scrolling="no"
                src="https://walrus-app-bzt6a.ondigitalocean.app/"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="mt-12 mb-14 w-full">
          <Slider {...settings}>
            {logos.map((logo, index) => (
              <div key={index} className="container">
                <img src={logo} alt="logo" />
              </div>
            ))}
          </Slider>
        </section>
        {/* <section className="my-10">
          <h2 className="section-title text-4xl mb-5 text-center">Assets Activity</h2>
          <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored" />
          <AssetsMinted />
        </section>
        <section className="my-10">
          <h2 className="section-title text-4xl mb-5 text-center">Gas Savings Calculator</h2>
          <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored" />
          <GasCalculator />
        </section> */}

        {/* <!-- USE CASES --> */}
        <section id="section-usecases">
          <UseCases />
        </section>
        {/* Skip out of container for full-width      */}
        </div>
      
        <section className="my-8">
        <div className="grid grid-cols-1 sm:grid-cols-5 py-4 space-y-8 sm:space-y-0 bg-fvlightgrey bg-opacity-10 justify-center place-items-center">
          <div className="section-title text-xl">Backed by</div>
          <div>
            <img src={logo_early} alt="Earlybird" />
          </div>
          <div>
            <img src={logo_target} alt="Target Global" />
          </div>
          <div>
            <img src={logo_adara} alt="Adara" />
          </div>
          <div>
            <img src={logo_4F} alt="4Founders" />
          </div>
        </div>
      </section>
      {/* Back into container */}
      <div className="container mx-auto px-10">
      </div>
      <div className="flex justify-center align-bottom polkadot h-40 md:h-60 lg:h-96 w-auto">
        <img
          className="object-scale-down object-bottom w-1/4 pb-10"
          src={logo_polka}
          alt="Polkadot"
        />
      </div>
      {/* <!-- BACK INTO CONTAINER --> */}
      <div className="container mx-auto px-10">
        <section className="mb-10">
          <div className="flex justify-center">
            <p className="w-3/4 text-center">
              LAOS is a fully open Layer 1 blockchain, decentralized via usage
              of its own native utility token, and deployed as a parachain
              within Polkadot. Built with the guidance of the Substrate Builders
              Program, LAOS will inherit the security of the Polkadot ecosystem
              from day 1. LAOS will replicate a part of Polkadot&apos;s
              architecture inside Polkadot itself, providing its own
              sharding-based scaling specialized in Digital Ownership.
            </p>
          </div>
        </section>
        {/* <section id="section-roadmap" className="mb-20">
          <img
            className="sm:h-[100rem]  md:h-[130rem] lg:h-[150rem]  max-w-full mx-auto rounded-xl"
            src={roadmap}
            alt="roadmap"
          />
        </section> */}
        <section id="section-benefits" className="">
          <h2 className="section-title text-3xl md:text-4xl mb-5 mt-20 text-center">Benefits</h2>
          <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
          <div className="grid grid-cols-1 md:grid-cols-3  gap-3">
            <div className="bg-fvdarkpurple p-3 rounded-lg border-2 border-fvgreypurple">
              <img
                className="w-1/2"
                src={benefit_icon_scale}
                alt="Scalability"
              />
              <h3 className="text-2xl card-title text-fvorange w-3/4">
                Spend Less on Minting
              </h3>
              <p className="text-m">
                LAOS&apos;s minting fees are more than ten times lower than any
                other blockchain, yet assets still appear as regular NFTs in
                other chains. Save money without leaving the ecosystem of your
                choice.
              </p>
            </div>
            <div className="bg-fvdarkpurple p-3 rounded-lg border-2 border-fvgreypurple">
              <img
                className="w-1/2"
                src={benefit_icon_bridgeless}
                alt="Bridgeless"
              />
              <h3 className="text-2xl card-title text-fvorange w-3/4">
                Mint Assets in All Chains
              </h3>
              <p className="text-m">
                Seamless integration with all EVM chains from day one, and more
                to come in the future. Mint assets on other chains, such as
                Ethereum or Polygon,{" "}
                <span className="font-bold">without paying gas fees</span> for
                that chain
              </p>
            </div>
            <div className="bg-fvdarkpurple p-3 rounded-lg border-2 border-fvgreypurple">
              <img
                className="w-1/2"
                src={benefit_icon_interop}
                alt="Interoperability"
              />
              <h3 className="text-2xl card-title text-fvorange w-3/4">
                Cross-Application Utility
              </h3>
              <p className="text-m">
                Use LAOS{" "}
                <span className="font-bold">
                  Decentralized Asset Metadata Extension
                </span>{" "}
                to permissionlessly add new functionality to existing assets,
                enabling them to be used as desired in other applications or
                games.{" "}
              </p>
            </div>
          </div>
        </section>
      </div>
        <section>
          <PoweredByLAOS />
        </section>
      <div className="container mx-auto px-10">
        {/* VIDEOS */}
        <section id="bridgeless-minter" className="my-20">
          <EmbedVideo 
            title="What is Bridgeless Minting"
            url="https://www.youtube.com/embed/rBEYpxq63tI?si=qJVaPV8io_gaHxum"
          />
        </section>
        <section id="community" className="mb-20">
          <h2 className="section-title text-4xl mb-5 text-center">Join Our Community</h2>
          <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
          <h5 className="text-xl py-3">Why join the community?</h5>
          <p className="mb-5">
            Our community is where{" "}
            <span className="font-bold text-fvyellow">developers</span> can
            learn how to
            <span className="font-bold text-fvyellow">
              {" "}
              build applications
            </span>{" "}
            on LAOS and
            <span className="font-bold text-fvyellow">
              {" "}
              apply for our upcoming grant program
            </span>
            , and where those interested in the{" "}
            <span className="font-bold text-fvyellow">LAOS token </span>
            can keep up to date with news regarding the crowdloan and token
            launch.
          </p>
          <CommunityPanels />
          <h5 className="text-xl py-3 mt-2">Collaborate with us</h5>
          <p>
            Want to collaborate with LAOS?{" "}
            <a href="/collaborate/" className="font-bold text-fvyellow">
              Get in touch
            </a>{" "}
            to let us know how we can work together!
          </p>
        </section>
        {/* Signup */}

        {/* <!-- SKIP OUT OF CONTAINER FOR FULL_WIDTH IMAGE --> */}
      </div>

      
      <Newsletter />

    </>
  );
};

export default Home;
