/* eslint-disable react/prop-types */ 
import React from 'react';
import Newsletter from './components/Newsletter';
import PoweredByLAOS from './components/PoweredByLAOS';
import img_laos_coin from './../img/LAOS_COIN.png';
import img_rain_coin from './../img/LAOS_RAIN_COIN.png';
// import img_stack_coin from './../img/LAOS_STACK_COIN.png';
import img_mexc from './../img/logo_mexc.svg';
import img_bit2me from './../img/logo_bit2me.svg';
import img_marketcap from './../img/logo_coinmktcap.svg';
import img_coingecko from './../img/coinge_logo.png';



const Token = () => {

    return (
    <>
    <section className="">
        <div className="container mx-auto pb-10">
            <div className="flex flex-col place-items-center">
                <div className="md:mr-[-45px]">
                    <img src={img_laos_coin} alt="laos coin" />
                </div>
                <h1 className="justify-center text-center text-fvyellow pb-3 header-title text-3xl md:text-5xl leading-none lg:leading-tight">
                    $LAOS Token
                </h1>
                <div className='justify-center text-center text-xl md:text-2xl '>
                    Powering the creation of millions of assets on any EVM chain. 
                </div> 
                <div className='mt-8 text-center'>
                    <a
                    href="https://laos.gitbook.io/laos-whitepaper"
                    className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                    >
                    LAOS Tokenomics
                    </a>
                </div>
            </div>
        </div>
    </section >
    <section className='pb-8'>
        <h2 className="section-title text-3xl md:text-4xl my-10 text-center">What is $LAOS</h2>
        <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
        <div className="flex flex-col items-center justify-center">
            <div className="grid grid-cols-1 md:grid-cols-2 md:w-3/4 items-center justify-center">
                <div className="px-10 md:px-0 md:order-last order-first grid">
                    <img src={img_rain_coin} alt="coins" className="justify-self-end"/>
                </div>
                <div className="text-xl md:text-xl px-4 md:px-0 text-center md:text-left">
                    The $LAOS utility token powers the entire LAOS ecosystem, ensuring secure,
                    efficient, and fair operations.<br /><br />

                    $LAOS enables users to execute transactions, contribute to network security 
                    through staking, and participate in governance.<br /><br />

                    In March 2024, Switzerland&apos;s financial regulator FINMA officially classified 
                    $LAOS as a Utility Token. Find out more&nbsp; 
                    <a className="text-fvyellow hover:underline" href="https://medium.com/laosnetwork/laos-token-classified-as-a-utility-token-by-switzerlands-financial-regulator-finma-7a73cf756a95">
                     here
                    </a>.
                </div> 
            </div>
        </div>
        <h2 className="section-title text-3xl md:text-4xl my-10 text-center">How to exchange $LAOS?</h2>
        <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
        <div className="flex flex-col items-center justify-center">
            <div className="mb-4 mt-4 md:mb-8 grid grid-cols-1 md:grid-cols-3 md:w-3/4 space-x-2 place-items-center items-start justify-center">
                <div className="px-10 md:px-0">
                    <img src={img_mexc} alt="coins" className="py-10"/>
                </div>
                <div className="text-md col-span-2 md:text-md px-4 md:px-0 text-center md:text-left">
                    <p className="mb-4">$LAOS token is available to buy and sell at&nbsp; 
                        <a className="text-fvyellow hover:underline" href="https://mexc.com">MEXC.com</a></p>
                    <p className="mb-4">Deposits via EVM (e.g. Metamask) and Substrate (e.g. Polkadot.JS) are supported*.
                    </p>
                    <div className='my-8 text-center'>
                        <a
                            href="https://www.mexc.com/es-ES/exchange/LAOS_USDT"
                            className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                            >
                            Exchange $LAOS at MEXC.com
                        </a>
                    </div>
                </div> 
            </div>
            <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
            <div className="mt-4 md:mt-8 grid grid-cols-1 md:grid-cols-3 md:w-3/4 space-x-2 place-items-center items-start justify-center">
                <div className="px-10 md:px-0">
                    <img src={img_bit2me} alt="coins" className="py-16"/>
                </div>
                <div className="text-md col-span-2 md:text-md px-4 md:px-0 text-center md:text-left">
                    <p className="mb-4">$LAOS token is available to buy and sell at&nbsp; 
                        <a className="text-fvyellow hover:underline" href="https://bit2me.com">Bit2Me.com</a></p>
                    <p className="mb-4">Important Note: $LAOS deposits are not currently supported. 
                        
                    </p>
                    <div className='my-8 text-center'>
                        <a
                            href="https://pro.bit2me.com/exchange/LAOS-USDT"
                            className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                            >
                            Exchange $LAOS at Bit2Me.com
                        </a>
                    </div>
                </div> 
            </div>
        </div>
        <div className="text-xs text-center">
        *Deposits to MEXC from Substrate multisigs and proxies are not supported (as with $DOT).
        </div>
    </section>
    <section>
        <hr className="w-[100%] h-[2px] border-0 bg-fvpurple" />
        <div className="flex flex-col md:flex-row md:space-x-16 space-y-4 md:space-y-0 px-16 my-8 justify-center">
            <a href="https://www.mexc.com/es-ES/exchange/LAOS_USDT"><img className="h-10" src={img_mexc} alt="mexc" /></a>
            <a href="https://pro.bit2me.com/exchange/LAOS-USDT"><img className="h-10" src={img_bit2me} alt="bit2me" /></a>
            <a href="https://coinmarketcap.com/currencies/laos-network/"><img className="h-10" src={img_marketcap} alt="cointmarketcap" /></a>
            <a href="https://www.coingecko.com/en/coins/laos-network"><img className="h-10" src={img_coingecko} alt="coingecko" /></a>
            
        </div>
        <hr className="w-[100%] h-[2px] border-0 bg-fvpurple" />
    </section>
    
    <section>
        <PoweredByLAOS />
    </section>
    <section>
        <Newsletter />
    </section>
    </>
    );
};

export default Token;